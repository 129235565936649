import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-whatsapp-chat',
  templateUrl: './whatsapp-chat.component.html',
  styleUrls: ['./whatsapp-chat.component.scss']
})
export class WhatsappChatComponent implements OnInit {
  whatsapp = false;

  constructor() { }

  ngOnInit(): void {
  }

  openWhats(){
    if(this.whatsapp === true){
      this.whatsapp= false;
    }else{
      this.whatsapp= true;
    }
  }

}
