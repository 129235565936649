<div class="cont_btn" fxLayout="column" fxLayoutAlign="center center">

    <div *ngIf="whatsapp === true" class="cont_chat">
        <div class="chat_header">
            <h1>Estamos para ayudarte</h1>
        </div>
        <div class="card_btn">
            <a href="https://api.whatsapp.com/send?phone=0123456789&text=Hola, Nececito mas informacion!" target="_blank">
                Envíanos un
                mensaje de WhatsApp</a>
        </div>
        <div class="card_btn">
            <a href="https://api.whatsapp.com/send?phone=0123456789&text=Hola, Nececito mas informacion!" target="_blank">
                Envíanos un
                mensaje de WhatsApp</a>
        </div>
    </div>
    <img class="log_whatsapp" (click)="openWhats()" src="../../../assets/images/logos/whatsapp_chat.svg" alt="">
</div>