import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Location } from '@angular/common'


@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  navMobile = false;

  items = [
    {
      name: 'Blog',
      url: 'https://okempleos.com/blog/',
    },
    {
      name: 'Ferias Laborales',
      url: 'https://okempleos.com/eventos/',
    },
    {
      name: 'Calculadora Salarial',
      url: 'https://okempleos.com/calculadora-salarial/',
    },
    {
      name: 'Planes',
      url: '/planes',
    },
    {
      name: 'Contacto',
      url: 'https://api.whatsapp.com/send?phone=573108668776&text=!Hola%C2%A1',
    },
    {
      name: 'Empresas',
      url: 'https://okempleos.com/empresas/',
    }
  ]

  currentStateEmpresa = '0';
  currentStateCandidato = "1";

  constructor(
    public dialog: MatDialog,
    private location: Location

  ) { }

  ngOnInit(
  ): void {
  }

  validMenu(){
    if(this.navMobile === true){
      this.navMobile = false;
    }else{
      this.navMobile = true; 
    }
  }

  back(): void {
    this.location.back()
  }

}
