import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { GlobalConstants } from 'app/common/global-constants';
import { CandidatoVacantesService } from '../../main/vacante/candidato-vacantes/candidato-vacantes.service';

@Component({
  selector: 'app-modal-reportar-vacante',
  templateUrl: './modal-reportar-vacante.component.html',
  styleUrls: ['./modal-reportar-vacante.component.scss']
})
export class ModalReportarVacanteComponent implements OnInit {
  urlLocal = GlobalConstants.apiURL;
  modal2 = false;
  motivos: any[];
  public reporte: {
    vacante_id: number,
    motivo: number,
    descripcion: string,
  }
  forms = new FormGroup({
    vacante_id: new FormControl(this.data['id'], Validators.required),
    vacante_cargo: new FormControl(this.data['cargo']),
    vacante_descripcion: new FormControl(this.data['descripcion']),
    vacante_empresa: new FormControl(this.data['empresa'].nombre),
    vacante_nit: new FormControl(this.data['empresa'].nit),
    descripcion: new FormControl('', Validators.required),
    motivo: new FormControl('', Validators.required),
  });

  constructor(
    private _formBuilder: FormBuilder,
    private _CandidatoVacantesService: CandidatoVacantesService,
    public dialogRef: MatDialogRef<ModalReportarVacanteComponent>,
    @Inject(MAT_DIALOG_DATA) public data: string[],
    private _httpClient: HttpClient,
  ) {
    this.motivos = this._CandidatoVacantesService.motivo;
  
  }

  ngOnInit(): void {
  }

  onCerrarClick(): void {
    this.dialogRef.close();
  }

  postReporte(data): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.post(`${this.urlLocal}reportar_vacante`, data)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });

  }

  denunciar() {
    this.modal2 = true;
    const reporte = {
      vacante_id: this.forms.value.vacante_id,
      motivo: this.forms.value.motivo,
      descripcion: this.forms.value.descripcion,
    };

    console.log('datos formulario',this.forms);

    this.postReporte(reporte).then((response) => { 
      console.log('se envio satisfactoriamente');
    }).catch(err => {
      console.error(err);
    });

  }
}
