<div class="cont_page">
    <app-navbar></app-navbar>
    <header>
        <h1>Calculadora Salarial</h1>
        <p>Esta es una calculadora salarial de carácter pedagógico, que tiene como propósito enseñarte el estimado de tu
            salario mensual según tu salario base y descuentos por prestaciones sociales, además de agregar otros
            descuentos:
        </p>
    </header>
    <main>

    </main>
    <footer>
        
    </footer>
</div>