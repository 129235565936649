
<div mat-dialog-content fxLayout="column" >

    <div class="logo">
        <img class="diamondMo" [src]="img"  alt="img">
    </div>

    <div  fxLayout="row" fxLayoutAlign="center center" class="mb-8">
        <span class="texto h2">{{texto}}</span>
    </div>

    <div fxLayout="row" fxLayoutAlign="center center" class="pt-24">
        <button  mat-raised-button color="primary" (click)="onCerrarClick()">Cerrar</button>
    </div>

</div>

