import { FuseNavigation } from '@fuse/types';

export const navigation_empresa: FuseNavigation[] = [
    {
        id: 'home',
        title: 'Inicio',
        //translate: 'NAV.HOME',
        type: 'item',
        isItemBigTitle: true,
        svgIcon: 'assets/images/iconos-sidebar/OkEmpleos_Sidebar_Empresa_01_Inicio.svg',
        url: '/apps/dashboards/analytics',
        tutorial: 'Visualiza el resumen de tu actividad y conoce las principales funcionalidades de la Plataforma.'
    },     
    {
        id: 'empresa',
        title: 'Empresa',
        //translate: 'NAV.MY_INFORMATION',
        type: 'group',
        icon: 'apps',
        children: [
            {
                id: 'datos_empresa',
                title: 'Perfil',
                //translate: 'NAV.MY_DATA',
                type: 'collapsable',
                isItemBigTitle: true,
                svgIcon: 'assets/images/iconos-sidebar/OkEmpleos_Sidebar_Empresa_02_Perfil.svg',
                children: [
                    {
                        id: 'datos_generales',
                        title: 'Información',
                        type: 'item',
                        isItemTitle: true,
                        //translate: 'NAV.PERSONAL_DATA',
                        svgIcon: 'assets/images/iconos-sidebar/OkEmpleos_Sidebar_Empresa_03_Informacion.svg',
                        url: '/empresa/informacion/informaciongeneral',
                        tutorial: ''
                    },
                    {
                        id: 'contacto',
                        title: 'Contacto',
                        type: 'item',
                        isItemTitle: true,
                        //translate: 'NAV.ACADEMIC_DATA',
                        svgIcon: 'assets/images/iconos-sidebar/OkEmpleos_Sidebar_Empresa_04_Contacto.svg',
                        url: '/empresa/informacion/contacto',
                        tutorial: ''
                    },
                    {
                        id: 'redes_sociales',
                        title: 'Redes',
                        type: 'item',
                        isItemTitle: true,
                        //translate: 'NAV.EXPERIENCE',
                        svgIcon: 'assets/images/iconos-sidebar/OkEmpleos_Sidebar_Empresa_05_Redes.svg',
                        url: '/empresa/informacion/redessociales',
                        tutorial: ''
                    },
                    {
                        id: 'perfil',
                        title: 'Perfil',
                        isItemTitle: true,
                        type: 'item',
                        //translate: 'NAV.EXPERIENCE',
                        svgIcon: 'assets/images/iconos-sidebar/OkEmpleos_Sidebar_Empresa_06_Perfil.svg',
                        url: '/empresa/informacion/perfil',
                        tutorial: ''
                    }
                ],
                tutorial: 'Actualiza la información general, datos de contacto, redes sociales y descripción del perfil de tu empresa.'
            },
            {
                id: 'admin_usuarios',
                title: 'Administración',
                //translate: 'NAV.MY_DATA',
                type: 'collapsable',
                isItemBigTitle: true,
                svgIcon: 'assets/images/iconos-sidebar/OkEmpleos_Sidebar_Empresa_07_Administracion.svg',
                children: [
                    {
                        id: 'usuarios',
                        title: 'Usuarios',
                        type: 'item',
                        isItemTitle: true,
                        //translate: 'NAV.PERSONAL_DATA',
                        svgIcon: 'assets/images/iconos-sidebar/OkEmpleos_Sidebar_Empresa_08_Informacion.svg',
                        url: '/empresa/administracion/usuarios',
                        tutorial: ''
                    },
                    {
                        id: 'roles',
                        title: 'Roles',
                        type: 'item',
                        isItemTitle: true,
                        //translate: 'NAV.PERSONAL_DATA',
                        svgIcon: 'assets/images/iconos-sidebar/OkEmpleos_Sidebar_Empresa_09_Roles.svg',
                        url: '/empresa/administracion/roles',
                        tutorial: ''
                    },
                    {
                        id: 'facturacion',
                        title: 'Facturación',
                        type: 'item',
                        isItemTitle: true,
                        //translate: 'NAV.PERSONAL_DATA',
                        svgIcon: 'assets/images/iconos-sidebar/OkEmpleos_Sidebar_Empresa_10_Facturacion.svg',
                        url: '/empresa/administracion/facturacion',
                        tutorial: ''
                    },     
                    {
                        id: 'resumen_plan',
                        title: 'Resumen plan',
                        type: 'item',
                        isItemTitle: true,
                        //translate: 'NAV.PERSONAL_DATA',
                        svgIcon: 'assets/images/iconos-sidebar/OkEmpleos_Sidebar_Empresa_11_ResumenPlan.svg',
                        url: '/empresa/administracion/resumenplan',
                        tutorial: ''
                    },                
                    {
                        id   : 'planes',
                        title: 'Planes',
                        type : 'item',
                        isItemTitle: true,
                        svgIcon: 'assets/images/iconos-sidebar/OkEmpleos_Sidebar_Empresa_12_Planes.svg',
                        url  : '/empresa/planes',
                        tutorial: ''
                    },
                    {
                        id: 'contacto_asesor',
                        title: 'Contactar asesor',
                        type: 'item',
                        isItemTitle: true,
                        //translate: 'NAV.PERSONAL_DATA',
                        svgIcon: 'assets/images/iconos-sidebar/OkEmpleos_Sidebar_Empresa_13_Contacto.svg',
                        externalUrl: true,
                        openInNewTab: true,
                        url: 'https://api.whatsapp.com/send?phone=573144041975&text=¡Hola!%20Te%20contacto%20desde%20OkEmpleos.com',
                        tutorial: ''
                    },
                ],
                tutorial: 'Gestiona los Usuarios, Roles y Facturas de tu empresa. Además, conoce el resumen de tu plan.'
            },
            {
                id: 'perfil_empresa',
                title: 'Perfil público',
                isItemBigTitle: true,
                //translate: 'NAV.MY_APPLICATIONS',
                type: 'item',
                svgIcon: 'assets/images/iconos-sidebar/OkEmpleos_Sidebar_Empresa_14_PerfilPublico.svg',
                url: '/empresa/profile',
                tutorial: 'Observa el perfil público de tu empresa, así es como te verán los candidatos cuando ingresen al perfil. ¡No olvides mantenerlos actualizado!'
            },
        ]
    },  
    {
        id: 'vacantes',
        title: 'Mi gestión',
        //translate: 'NAV.MY_INFORMATION',
        type: 'group',
        icon: 'apps',
        children: [    
            {
                id: 'crear_vacante',
                title: 'Nueva vacante',
                type: 'item',
                isItemBigTitle: true,
                //translate: 'NAV.PERSONAL_DATA',
                svgIcon: 'assets/images/iconos-sidebar/OkEmpleos_Sidebar_Empresa_15_NuevaVacante.svg',
                url: '/vacante/nueva-vacante',
                tutorial: 'Crea una nueva vacante con todos los requisitos, incluso puedes agregar información adicional de contacto y una pregunta filtro.'
            },
            {
                id: 'listado_vacantes',
                title: 'Vacantes',
                isItemBigTitle: true,
                type: 'item',
                //translate: 'NAV.PERSONAL_DATA',
                svgIcon: 'assets/images/iconos-sidebar/OkEmpleos_Sidebar_Empresa_14_Vacantes.svg',
                url: '/vacante/empresa-vacantes/lista',
                tutorial: 'Filtra y organiza todas tus vacantes creadas, y mira su estado y el número de candidatos que han aplicado.'
            },
            {                
                id: 'lista-candidatos',
                title: 'Candidatos',
                isItemBigTitle: true,
                type: 'item',
                //translate: 'NAV.EXPERIENCE',
                svgIcon: 'assets/images/iconos-sidebar/OkEmpleos_Sidebar_Empresa_16_Candidatos.svg',
                url: '/empresa/lista-candidatos',
                tutorial: 'Busca y filtra de forma detallada los candidatos que más se ajusten a tus parámetros de búsqueda.'               
            },
            {                
                id: 'lista-preseleccionados',
                title: 'Pre seleccionados',
                isItemBigTitle: true,
                type: 'item',
                //translate: 'NAV.EXPERIENCE',
                svgIcon: 'assets/images/iconos-sidebar/OkEmpleos_Sidebar_Empresa_17_Preseleccionados.svg',
                url: '/empresa/lista-preseleccionados',
                tutorial: 'Conoce el listado de Candidatos que has agregado como Preseleccionados.'               
            },
            {                
                id: 'estudio-seguridad',
                title: 'Estudio de confiabilidad',
                isItemBigTitle: true,
                type: 'item',
                svgIcon: 'assets/images/iconos-sidebar/OkEmpleos_Sidebar_Empresa_Estudio_Seguridad.svg',
                url: '/empresa/estudio-seguridad',
                tutorial: 'Verifica la identidad de tus candidatos.'               
            },
            {                
                id: 'procesos-seleccion',
                title: 'Procesos de selección',
                isItemBigTitle: true,
                type: 'item',
                svgIcon: 'assets/images/iconos-sidebar/OKEmpleos_Sidebar_Empresa_19_Procesos_Seleccion.svg',
                url: '/empresa/procesos-seleccion',
                tutorial: 'Realiza pruebas psicotécnicas a tus candidatos.'               
            }         
        ]
    },
    {
        id: 'colaboradores',
        title: 'Colaboradores',
        //translate: 'NAV.MY_INFORMATION',
        type: 'group',
        icon: 'apps',
        children: [
            {                
                id: 'lista-colaboradores',
                title: 'Colaboradores',
                isItemBigTitle: true,
                type: 'item',
                //translate: 'NAV.EXPERIENCE',
                svgIcon: 'assets/images/iconos-sidebar/OkEmpleos_Sidebar_Empresa_18_Colaboradores.svg',
                url: '/empresa/lista-colaboradores',
                tutorial: 'Revisa el listado de Colaboradores, es decir, todos aquellos usuarios de Ok Empleos que se han registrado como trabajadores actuales o antiguos en tu empresa.'            
            }         
        ]
    },      
    {
        id: 'interes',
        title: 'De mi interés',
        //translate: 'NAV.MY_INFORMATION',
        type: 'group',
        icon: 'apps',
        children: [
            {
                id: 'centro_ayuda',
                title: 'Ayuda',
                type: 'item',
                isItemBigTitle: true,
                //translate: 'NAV.PERSONAL_DATA',
                svgIcon: 'assets/images/iconos-sidebar/OkEmpleos_Icono_07_Ayuda.svg',
                url: '/tutoriales/lista',
                tutorial: ''//'¿Necesitas ayuda? Aquí encontrarás nuestro Centro de Ayuda con videos para que conozcas la plataforma.'
            },
            {
                id: 'blog',
                title: 'Blog',
                type: 'item',
                isItemBigTitle: true,
                //translate: 'NAV.PERSONAL_DATA',
                svgIcon: 'assets/images/iconos-sidebar/OkEmpleos_Icono_10_Blog.svg',               
                externalUrl: true,
                openInNewTab: true,
                url: 'https://okempleos.com/blog/',
                tutorial: ''//'En el Blog podrás encontrar artículos de tu interés.'
            },
        ]
    }, 
];