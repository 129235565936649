<div fxLayoutAlign="none" class="cont">

    <div class="cont_section section-izq">
        <img class="img_secti" src="../../../assets/images/logos/icon_alert.png" alt="">
    </div>

    <div class="cont_section section-der">
        <span class="close" (click)="onCerrarClick()">X</span>
        <img class="err" src="../../../assets/images/logos/out.png" alt="">

        <b class="bold">¡Ops! Perfil incompleto </b>

        <div fxLayout="row" fxLayoutAlign="center center">
            <span class="texto h2">La empresa necesita por lo menos el<b> 75% de tu perfil,</b>
                por favor completa tu informacion para poder aplicar a la vacante</span>
        </div>

        <b class="bold">Completa los siguientes campos:</b>

        <div class="items" fxLayout="row">
            <div class="card_item" *ngFor="let item of itemsChecklist">
                <img class="intem_icon achievement " [src]="'assets/images/logros/' + item.icon"  alt="icon">
                <p>{{item.name}}</p>
            </div>
        </div>

        <div fxLayout="row" class="section-buttons">
            <button mat-raised-button class="ml-4" (click)="onCerrarClick()">Más tarde</button>
            <button mat-raised-button class="ml-4 bnt-orange" [routerLink]="'/candidato/informacionpersonal'">Completar
                ahora</button>
        </div>
    </div>

</div>