<div class="cont_nav--mobiles" fxFlex="100" *ngIf="navMobile === true">
    <div class="cont_itmes" fxFlex="70" fxLayout="column">
        <span class="close" (click)="validMenu()">X</span>
        <div class="items_group">
            <button class="btn btn_black" [routerLink]="'/login'" aria-label="LogIn">Iniciar Sesión</button>
            <button class="btn btn_blue" [routerLink]="'/registro'" aria-label="SignIn" [queryParams]="{registro: 'empresa'}">Registrate
                como Empresa</button>
            <button class="btn btn_orange" [routerLink]="'/registro'" aria-label="SignIn" [queryParams]="{registro: 'candidato'}">Registrate
                como Candidato</button>
            <!-- <button class="btn btn_purple" [routerLink]="'/okupa'">Okupa</button> -->
        </div>
        <div class="items_group">
            <button class="btn btn_yellow" [routerLink]="'/login'" aria-label="LogIn">Publicar tu
                vacante</button>
        </div>
        <div class="items_group" fxLayout="row grap">
            <a fxFlex="100" *ngFor="let ite of items" fxLayoutAlign="center center" href="{{ite.url}}"
                class="btn btn_grey">{{ite.name}}</a>
        </div>
        <div class="items_group" fxLayout="row" fxLayoutAlign="center center">
            <a href="https://www.tiktok.com/@en.modo.ok?lang=es" target="_blank">
                <img src="../../../../assets/images/logos/tik-tok.png" alt="">
            </a>
            <a href="https://www.linkedin.com/company/ok-empleos/" target="_blank">
                <img src="../../../../assets/images/logos/linke_black.svg" alt="">
            </a>
            <a href="https://www.instagram.com/ok.empleos/" target="_blank">
                <img src="../../../../assets/images/logos/insta_black.svg" alt="">
            </a>
            <a href="https://www.facebook.com/OkEmpleosColombia" target="_blank">
                <img src="../../../../assets/images/logos/face_black.svg" alt="">
            </a>
        </div>
    </div>
</div>

<div class="cont_nav--comp">
    <nav fxFlex="100" fxFlex.gt-sm="100" class="navbar nav_desktop">
        <div class="cont_nav" fxLayoutAlign="space-between center">
            <div fxFlex="70" fxFlex.gt-sm="70" class="item_nav item_nav--left" fxLayout="row" fxLayoutAlign="space-between center">
                <img class="ok_log" src="{{ logoPath }}" alt="logo" width="115" height="102" (click)="back()">
                <ul class="ul_primay" fxLayout="row" fxLayoutAlign="space-between center">

                    <li class="li_primary">
                        <button class="btn_nav btn-wiht" aria-label="Empresas" style="color: #6E4FE3;"><img src="../../../../assets/images/logos/business.svg" alt="business" width="24" height="24" style="width:24px; height: auto;"><img src="../../../../assets/images/logos/arrow_down.svg" alt="down" width="20" height="12" style="width:20px; height: auto; margin-left:10%;">  </button> <span>Empresas</span>
                        <ul class="items_otions">
                            <li><a href="/planes">Planes</a></li>
                            <li><a href="https://api.whatsapp.com/send?phone=573108668776&text=!Hola%C2%A1" target="_blank">Solicitar
                                    asesoria</a></li>
                            <li><a [routerLink]="'/registro'" [queryParams]="{registro: 'empresa'}">Inscribeme como
                                    Empresa</a></li>
                            <li><a href="https://okempleos.com/adquirir_plan_ok_empleos.pdf" target="_parent">Adquirir un plan Ok Empleos</a></li>
                            <!-- <li><a href="https://okempleos.com/calculadora-salarial/">Calculadora Salarial</a></li>
                            <li><a href="https://okempleos.com/eventos/">Ferias Laborales</a></li> -->
                        </ul>
                    </li>
                    <li class="li_primary">
                        <button class="btn_nav btn-wiht" aria-label="Candidatos"> <img src="../../../../assets/images/logos/account.svg" alt="account" width="24" height="24" style="width:24px; height: auto;"><img src="../../../../assets/images/logos/arrow_down_orange.svg" alt="down" width="20" height="12" style="width:20px; height: auto; margin-left:10%;"> </button> <span style="color: #5C166C;">Candidatos</span>
                        <ul class="items_otions">
                            <li><a [routerLink]="'/registro'" [queryParams]="{registro: 'candidato'}">Registrarme</a>
                            </li>
                            <li><a [routerLink]="'/lista-vacantes'">Buscar Vacantes</a></li>
                            <li><a href="https://www.youtube.com/channel/UCE56i6YHPlYBrpATAilz3XQ?" target="_blank">Escuela de la
                                    empleabilidad</a></li>
                            <li><a href="https://okempleos.com/calculadora-salarial/">Calculadora Salarial</a></li>
                            <li><a href="https://okempleos.com/Practica_entrevistas_trabajo_RIO.pdf">Practica para tus entrevistas laborales</a></li>
                            <li><a href="https://okempleos.com/eventos/">Ferias Laborales</a></li>
                            <!-- <li><a href="https://okempleos.com/eventos/">Eventos laborales </a></li> -->
                            <li><a href="https://www.youtube.com/watch?v=RJLpMD28QNA&list=PLUP-zPewRTowjcsxy6Id_MwEgWWWcaew-&ab_channel=OkEmpleos" target="_blank">Ver
                                    tutoriales</a></li>
                        </ul>
                    </li>
                    <li class="li_primary">
                        <button class="btn_nav btn-wiht" aria-label="Academia" style="padding-left: 30%;;"> <a href="https://academia.okempleos.com/" style="color: #402D86; font-family: 'Montserrat'; font-weight: 500;" aria-label="Academia"><img src="../../../../assets/images/logos/academy.svg" alt="" width="38" height="24" style="height:24px; width: auto;"> </a></button> <span>Academia</span>
                    </li>
                    <li class="li_primary">
                        <button class="btn_nav btn-wiht" aria-label="Blog" style="padding-left: 5%;"> <a href="https://okempleos.com/blog/" style="color: #402D86; font-family: 'Montserrat'; font-weight: 500;" aria-label="Blog"><img src="../../../../assets/images/logos/blog.svg" alt="" width="32" height="32" style="height:32px; width: auto;"> </a></button> <span>Blog</span>
                    </li>
                </ul>
            </div>
            <div fxFlex="30" fxFlex.gt-sm="50" class="item_nav" fxLayout="row" fxLayoutAlign="end center">
                <mat-divider vertical style="height:43px; border-right-color: #434343; border-right-width: 1px;"></mat-divider>
                <button class="btn_nav btn-login" aria-label="LogIn" [routerLink]="'/login'"> <img src="../../../../assets/images/logos/login.svg" width="" alt="LogIn">Iniciar sesión</button>
                <ul class="ul_second" fxLayout="row" fxLayoutAlign="center center">
                    <li class="li_second">
                        <button class="btn_nav btn-wiht--two" aria-label="SignUp">Unirse ahora</button>
                        <ul class="items_otions">
                            <li><a [routerLink]="'/registro'" [queryParams]="{registro: 'empresa'}">Regístrate como empresa</a></li>
                            <li><a [routerLink]="'/registro'" [queryParams]="{registro: 'candidato'}">Regístrame como candidato</a>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
    <nav class="navbar nav_movile">
        <div class="cont_nav" fxLayoutAlign="space-between center">
            <div fxFlex="50" class="item_nav" fxLayoutAlign="start center">
                <img class="hamburger" (click)="validMenu()" src="/assets/images/home/menu_burger.svg" alt="Menu">
                <img class="ok_log item_nav--left" src="/assets/images/logos/logo.svg" alt="logo" (click)="back()">               
            </div>
            <div fxFlex="50" class="item_nav" fxLayoutAlign="end center">
                <button class="btn_nav btn-login" aria-label="LogIn" [routerLink]="'/login'"> <img src="../../../../assets/images/logos/login.svg" width="" alt="LogIn">Iniciar sesión</button>
                <ul class="ul_second" fxLayout="row" fxLayoutAlign="center center">
                    <li class="li_second">
                        <button class="btn_nav btn-wiht--two" aria-label="SignUp">Unirse ahora</button>
                        <ul class="items_otions">
                            <li><a [routerLink]="'/registro'" [queryParams]="{registro: 'empresa'}">Regístrate como empresa</a></li>
                            <li><a [routerLink]="'/registro'" [queryParams]="{registro: 'candidato'}">Regístrame como candidato</a>
                            </li>
                        </ul>
                    </li>
                </ul>              
            </div>
        </div>
    </nav>
</div>