
<div mat-dialog-content fxLayout="column" >


    <div *ngIf="vacante.aprobada" fxLayout="row" fxLayoutAlign="center center" class="mb-8" style="margin-bottom: 0px !important;">
        <span class="texto">¡<strong>Obtén</strong> más aplicaciones!</span>
    </div>
    
    <div *ngIf="!vacante.aprobada" fxLayout="row" fxLayoutAlign="center center" class="mb-8" style="margin-bottom: 0px !important;">
        <span class="texto">¡Tu vacante será publicada <strong>pronto</strong>!</span>
    </div>
    <div *ngIf="!vacante.aprobada" fxLayout="row" fxLayoutAlign="center center" class="mb-8">
        <span class="paragrafos"><strong>Estamos validando tu empresa</strong>, <br>no te preocupes esto no demorará mucho tiempo <br> y tus vacantes quedarán  <strong>automáticamente publicadas.</strong></span>
    </div>

    <div *ngIf="vacante.aprobada" fxLayout="row" fxLayoutAlign="center center" class="mb-8">
        <span class="paragrafos">Comparte tu vacante ahora</span>
    </div>

    <div class="share">
        <img src="assets/images/social/share.svg" alt="logo">
    </div>
    <!-- <div fxLayout="row" fxLayoutAlign="center center" class="mb-8">
        <span *ngIf="tipo === 'whatsapp'" class="texto h2">Ingresa el número para compartir por WhatsApp</span>
        <span *ngIf="tipo !== 'whatsapp'" class="texto h2">Ingresa el correo para compartir la vacante</span>
    </div> -->

    <div *ngIf="vacante.aprobada" fxLayout="row" fxLayoutAlign="center center" class="mb-8">
        <button  mat-icon-button class="share-icon" (click)="shareFacebook()">
                <img src="assets/images/social/facebook.svg" alt="facebook">
        </button>
        <button  mat-icon-button class="share-icon" (click)="shareWhatsapp()">
                <img src="assets/images/social/whatsapp.svg" alt="whatsapp">
        </button>
        <button  mat-icon-button class="share-icon" (click)="shareLinkedin()">
                <img src="assets/images/social/linkedin.svg" alt="linkedin">
        </button>
        <button  mat-icon-button class="share-icon" (click)="shareTwitter()">
                <img src="assets/images/social/twitter.svg" alt="twitter">
        </button>
    </div>

    <div *ngIf="vacante.aprobada" fxLayout="row" fxLayoutAlign="left center" class="mb-8">
        <mat-label class="enlace">Enlace</mat-label>
    </div>

    
    <div *ngIf="vacante.aprobada" fxLayout="row wrap" class="mb8" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <div fxFlex="100" fxFlex.gt-sm="75">
            <mat-form-field appearance="outline" fxFlex="100">
                <input matInput  type="text" value="https://co.okempleos.com/public/detalle-vacante/{{vacante.slug}}" disabled>
            </mat-form-field>
        </div>
        <div fxFlex="100" fxFlex.gt-sm="20" fxLayoutAlign="rigth center">
            <button  mat-flat-button  color="primary" aria-label="view" (click)="copyText()" style="border-radius: 6px !important; margin-left: 13px; padding: inherit;">
                Copiar
            </button>   
        </div>
    </div>

    <div *ngIf="vacante.aprobada" fxLayout="row wrap" class="mb8" fxLayoutAlign="end center" fxFlex="1 0 auto">
        <button  mat-flat-button  color="primary" aria-label="view" (click)="publish()" style="border-radius: 6px !important; margin-left: 13px; margin-top: 20px;">
            <img src="assets/images/social/flag.svg" alt="share"> Publicar en redes sociales
        </button>  
    </div>
    
</div>

