import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Vacante } from 'app/main/vacante/candidato-vacantes/model/vacante.model';
import { GlobalConstants } from 'app/common/global-constants';
import { AuthStateService } from 'app/pixcore/shared/auth-state.service';


@Injectable()
export class CandidatoVacantesService implements Resolve<any>
{


    urlLocal = GlobalConstants.apiURL;
    urlProd = '';

    userId: number;
    pageIndexFiltro = 1;
    candidatoId: number;
    empresaId: number;
    routeParams: any;

    binbsEjecutados = false;

    onVacantesCandidatoChanged: BehaviorSubject<any>;
    onSearchTextVacantesCandidatoChanged: Subject<any>;
    onSearchTextVacantesCandidatoEnter: Subject<any>;
    onFilterVacantesCandidatoChanged: Subject<any>;
    onListadoChangedVacantes: BehaviorSubject<any>;

    candidatoAll: any;
    vacantes: Vacante[];
    vacantesLenght: number;
    vacantesFiltradas: Vacante[];
    
    searchText = '';
    filterBy = 'all';
    dataFiltro: any;


    estadosVacante: any;
    motivo: any[];
    tiposContrato: any[];
    sectoresEconomicos: any[];
    tiposJornada: any[];
    salarios: any[];
    areasVacante: any[];
    nivelesEducativos: any[];
    experiencias: any[];
    generos: any[];
    ciudades: any[];
    localidades: any[];
    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _httpClient: HttpClient,
        private authState: AuthStateService,
    ) {

        
        // Set the defaults
        this.onVacantesCandidatoChanged = new BehaviorSubject([]);
        this.onSearchTextVacantesCandidatoChanged = new Subject();
        this.onSearchTextVacantesCandidatoEnter = new Subject();
        this.onFilterVacantesCandidatoChanged = new Subject();
        this.onListadoChangedVacantes = new BehaviorSubject(false);

    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {

        this.candidatoId = this.authState.getCandidatoId();
        this.routeParams = route.params;
        this.empresaId = parseInt(this.routeParams.empresaId, 10);
        this.userId = this.authState.getUserId();

        return new Promise((resolve, reject) => {

            Promise.all([
                this.getEstadosVacante(),
                this.getVacantes(),
                this.getAll(),
                this.getAllSelects()
            ]).then(
                ([files]) => {

                    if (!this.binbsEjecutados) {


                        this.onFilterVacantesCandidatoChanged.subscribe(filter => {
                            this.filterBy = filter;

                            if (localStorage.getItem('estado')) {

                                if (this.filterBy === 'filtroApi') {
                            
                                    this.vacantes = this.vacantesFiltradas;
                                    this.onVacantesCandidatoChanged.next(this.vacantes);
    
                                }
    
                                if (this.filterBy === 'all' || this.filterBy === '') {
                                    this.getVacantes();
                                }
                                
                            }
                           
                        });

                        this.binbsEjecutados = true;
                    }

                    resolve('');
                },
                reject
            );
        });
    }

    /**
     * Get contacts
     *
     * @returns {Promise<any>}
     */
    getVacantes(page?: any): Promise<any> {

        this.pageIndexFiltro = page || 1;
        return new Promise((resolve) => {


            if (this.empresaId) { 
                this._httpClient.get(`${this.urlLocal}vacantes_empresa/${this.empresaId}/${this.candidatoId}?page=${page}`)
                .subscribe((response: any) => {

                    this.vacantes = response.data;
                    this.vacantesLenght = response.total;
                    this.onVacantesCandidatoChanged.next(this.vacantes);
                    resolve(this.vacantes);

                }, (err) => {

                    this.vacantes = [];
                    this.onVacantesCandidatoChanged.next(this.vacantes);
                    resolve(err);

                });

            }else {

                this._httpClient.get(`${this.urlLocal}vacantes_sin_aplicar/${this.candidatoId}?page=${page}`)
                .subscribe((response: any) => {
                    
                    this.vacantes = response.data;
                    this.vacantesLenght = response.total;
                    this.onVacantesCandidatoChanged.next(this.vacantes);
                    resolve(this.vacantes);

                }, (err) => {
                    this.vacantes = [];
                    this.onVacantesCandidatoChanged.next(this.vacantes);
                    resolve(err);

                });
            }
            
        }
        );
    }


    /**
     * Get todos los selects
     *
     * @returns {Promise<any>}
     */
    getAllSelects(): Promise<any> {
        return new Promise((resolve) => {
            this._httpClient.get(`${this.urlLocal}selects_candidato_vacantes_filtro`)
                .subscribe((response: any) => {
                    this.tiposContrato = response.tiposContrato;
                    this.sectoresEconomicos = response.sectoresEconomicos;
                    this.tiposJornada = response.tiposJornada;
                    this.salarios = response.salarios;
                    this.areasVacante = response.areasVacante;
                    this.nivelesEducativos = response.nivelesEducativos;
                    this.experiencias = response.experiencias;
                    this.generos = response.generos;
                    this.ciudades = response.ciudades;
                    this.localidades = response.localidades;
                    this.motivo = response.motivosDenuncia;
                    resolve(response);
                }, (err) => { 
                    this.motivo= [];
                    this.tiposContrato = [];
                    this.sectoresEconomicos = [];
                    this.tiposJornada = [];
                    this.salarios = [];
                    this.areasVacante = [];
                    this.nivelesEducativos = [];
                    this.experiencias = [];
                    this.generos = [];
                    this.ciudades = [];
                    this.localidades = [];
                    resolve(err);
                } );
        });
    }

    getAll(): Promise<any>
    {
        return new Promise((resolve) => {
            this._httpClient.get(`${this.urlLocal}candidato_all/${this.candidatoId}`)
                .subscribe((response: any) => {
                    this.candidatoAll = response[0];
                    resolve(this.candidatoAll);
                }, (err) => {
                    this.candidatoAll = null;

                    resolve(this.candidatoAll);
                });
        });
        
    }


    /**
     * Get estados de vacante
     *
     * @returns {Promise<any>}
     */
    getEstadosVacante(): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get(`${this.urlLocal}vacante_estados`)
                .subscribe((response: any) => {
                    this.estadosVacante = response;
                    resolve(response);
                }, reject);
        });
    }




    // pendiente ruta para filtrar
    
    filtrar(data, page?: any): Promise<any> {
        this.pageIndexFiltro = page || 1;
        this.dataFiltro = data;

        if (data.es_favorita === true) {
            data.es_favorita = 1
        }else if (data.es_favorita === false) {
            data.es_favorita = 2
        }

        return new Promise((resolve, reject) => {
            this._httpClient.post(`${this.urlLocal}vacantes_filtro_candidato?page=${page}`, data)
                .subscribe((response: any) => {

                    this.vacantesFiltradas = response.data;
                    this.vacantesLenght = response.total;

                    this.onFilterVacantesCandidatoChanged.next('filtroApi');
   
                    resolve(response);
                }, reject);
        });
    }

    /**
     * Post aplicacion
     * @param Data
     * @returns {Promise<any>}
     */
    postAplicacion(data): Promise<any>
    {
        return new Promise((resolve, reject) => {
            this._httpClient.post(`${this.urlLocal}aplicacion`, data)
                .subscribe((response: any) => {

                    if (this.filterBy === 'filtroApi') {
                        this.filtrar(this.dataFiltro);
                    }else {
                        this.getVacantes();
                    }

                    
                    resolve(response);
                }, reject);
        });

    }

    agregarFavorita(data): Promise<any>
    {
        return new Promise((resolve, reject) => {
            this._httpClient.post(`${this.urlLocal}vacante_favorita`, data)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });

    }

    eliminarFavorita(data): Promise<any>
    {
        return new Promise((resolve, reject) => {
            this._httpClient.post(`${this.urlLocal}vacante_favorita_delete`, data)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });

    }


}
