<div class="fuse-countdown">

    <div class="time days">
        <div class="value">
            {{countdown.days}}
        </div>
        <div class="title">
            días
        </div>
    </div>

    <div class="time hours">
        <div class="value">
            {{countdown.hours}}
        </div>
        <div class="title">
            horas
        </div>
    </div>

    <div class="time minutes">
        <div class="value">
            {{countdown.minutes}}
        </div>
        <div class="title">
            minutos
        </div>
    </div>

    <div class="time seconds">
        <div class="value">
            {{countdown.seconds}}
        </div>
        <div class="title">
            segundos
        </div>
    </div>

</div>