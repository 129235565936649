<div fxLayout="column" class="cont" *ngIf="modal2 === false">

    <span class="close" (click)="onCerrarClick()">X</span>
    <div class="opciones_denuncia">
        <h1>¿Cuál es la razón por la cual denunciara esta vacante?</h1>

        <p class="sub_text">Empresa a denunciar / vacante</p>
        <form [formGroup]="forms" class="form">
            <div>
                <mat-radio-group formControlName="motivo">
                    <mat-radio-button *ngFor="let mot of motivos" value={{mot.id}}>{{mot.nombre}}</mat-radio-button>
                    <br>
                </mat-radio-group>
            </div>
            <textarea formControlName="descripcion"
                placeholder="Por favor indicanos los detalles por la cual denunciaras la vacante"></textarea>

        </form>

        <div class="buttons" fxLayout="row" fxLayoutAlign="center center">

            <button class="mb-4 py-8 btn-white" mat-raised-button (click)="onCerrarClick()">
                Cancelar
            </button>
            <button class="mb-4 py-8 button-custom" color="accent" [disabled]="forms.invalid === true" mat-raised-button
                (click)="denunciar()">
                Denunciar
            </button>
        </div>

        <p class="terminos">
            Al hacer clic en <b>denunciar</b>, aceptas las <a href="https://app.okempleos.com/terminos"
                target="_blank">Condiciones <br> legales</a> y la <a
                href="https://okempleos.com/politicas-de-privacidad/" target="_blank"> política de
                privacidad</a> de <b>Ok Empleos.</b>
        </p>
    </div>
</div>

<div fxLayout="column" class="cont2" fxLayoutAlign="center center" *ngIf="modal2 === true">
    <span class="close" (click)="onCerrarClick()">X</span>
    <div fxLayout="column" fxLayoutAlign="center center" class="msn_confirmacion">
        <img class="icon" src="../../../assets/images/logos/icon_report.png" alt="">
        <h1><span>Gracias</span> por permitirnos mejorar</h1>
        <p>Para <span>Ok Empleos</span> es muy importante tu comentario, por eso revisaremos lo más pronto </p>
        <button class="mb-4 py-8 button-custom" color="accent" mat-raised-button (click)="onCerrarClick()">
            Cerrar
        </button>
    </div>
</div>