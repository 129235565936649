import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, Validators, FormBuilder} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';



@Component({
  selector: 'modal-empresa-compartir-vacante',
  templateUrl: './modal-empresa-compartir-vacante.component.html',
  styleUrls: ['./modal-empresa-compartir-vacante.component.scss']
})
export class ModalEmpresaCompartirVacanteComponent implements OnInit {
  
  vacante : any;

  constructor(
    public dialogRef: MatDialogRef<ModalEmpresaCompartirVacanteComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _snackBar: MatSnackBar,

    ) {
      this.vacante = data
    }

  ngOnInit(): void {
   
  
  }

  shareWhatsapp() {

    let mensaje ="¡Hola! Te invito a ver esta nueva oferta de empleo en OkEmpleos.com: "+`https://co.okempleos.com/public/detalle-vacante/${this.vacante.slug}`;
    let post = "https://api.whatsapp.com/send?text=" + mensaje.replace(" ", "%20");
    window.open(post, '_blank');
}


shareFacebook(): void {
  let mensaje = "¡Hola! Te invito a ver esta nueva oferta de empleo en OkEmpleos.com: ";
  let url = `https://co.okempleos.com/public/detalle-vacante/${this.vacante.slug}`;
  let post = "https://www.facebook.com/sharer/sharer.php?u=" + url;
  window.open(post, '_blank');
}

shareTwitter(): void {
    let mensaje = "¡Hola! Te invito a ver esta nueva oferta de empleo en OkEmpleos.com: ";
    let url = `https://co.okempleos.com/public/detalle-vacante/${this.vacante.slug}`;
    let post = "https://twitter.com/intent/tweet?text=" + mensaje.replace(" ", "%20")+ "&url=" + url;
    window.open(post, '_blank');
}

shareLinkedin(): void {
    let mensaje = "¡Hola! Te invito a ver esta nueva oferta de empleo en OkEmpleos.com: ";
    let url = `https://co.okempleos.com/public/detalle-vacante/${this.vacante.slug}`;
    let post = "https://www.linkedin.com/shareArticle?mini=true&url=" + url + "&title=" + mensaje.replace(" ", "%20");
    window.open(post, '_blank');
}

copyText(): void {

    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = 'https://co.okempleos.com/public/detalle-vacante/'+ `${this.vacante.slug}`;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);

    this.openSnackBar('Vacante copiada en el portapapeles', 'snack-success')


}

publish(): void {
  this.shareFacebook();
  this.shareLinkedin();
  this.shareTwitter();
  this.shareWhatsapp();
  this.dialogRef.close();
}

/**
 * Mostrar la respuesta al usuario
 * @param mensaje
 */
 private openSnackBar(mensaje: string, clase: string): void {
  this._snackBar.open(mensaje, 'x', {
   duration: 4000,
   horizontalPosition: 'end',
   verticalPosition: 'top',
   panelClass: clase,
 });
}


}
