import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { fuseAnimations } from '@fuse/animations';
import { PausableObservable, pausable } from 'rxjs-pausable';
import { interval } from 'rxjs';

@Component({
  selector: 'modal-progress-candidato',
  templateUrl: './modal-progress-candidato.component.html',
  styleUrls: ['./modal-progress-candidato.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations   : fuseAnimations
})
export class ModalProgressCandidatoComponent implements OnInit {
 
  texto = ''
  img = null;
  pausable: PausableObservable<number>;

  constructor(
    public dialogRef: MatDialogRef<ModalProgressCandidatoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  
    ) {

    }

  ngOnInit(): void {

    this.pausable = interval(800)
    .pipe(pausable()) as PausableObservable<number>;
    this.pausable.subscribe(this.shoot.bind(this));
    this.pausable.pause();

    this.toggleConfeti();

      switch(this.data) { 
        case 'foto_perfil': { 
          this.texto = 'Completaste el logro Foto de Perfil'
          this.img = 'assets/images/logros/foto.gif'
          break; 
        } 
        case 'evaluacion_competencias': { 
          this.texto = 'Completaste el logro Evaluación de competencias'
          this.img = 'assets/images/logros/evaluacion.gif'
          break; 
        }
        case 'hoja_de_vida': { 
          this.texto = 'Completaste el logro Hoja de Vida'
          this.img = 'assets/images/logros/hojavida.gif'
          break; 
        }
        case 'video': { 
          this.texto = 'Completaste el logro Video de presentación'
          this.img = 'assets/images/logros/video.gif'
          break; 
        }
        case 'experiencia': { 
          this.texto = 'Completaste el logro Experiencia'
          this.img = 'assets/images/logros/laboral.gif'
          break; 
        }
        case 'educacion': { 
          this.texto = 'Completaste el logro Educación'
          this.img = 'assets/images/logros/academico.gif'
          break; 
        }
        case 'verificar_email': { 
          this.texto = '¡Excelente! Tu correo fue verificado'
          this.img = 'assets/images/avatars/medal.png'
          break; 
        }
        default: { 
          this.texto = 'Completaste un logro'
          this.img = 'assets/images/avatars/medal.png'
          break; 
        } 
    } 
  }

  shoot() {
    try {
        this.confetti({
        spread: 150,
        particleCount: 150,
        startVelocity: 40,
        zIndex: 99999,
        origin: {
            y: 0.8
        }
        });
      } catch(e) {
          // noop, confettijs may not be loaded yet
      }
    }



    confetti(args: any) {
    return window['confetti'].apply(this, arguments);
    }

    toggleConfeti(): void {
        this.pausable.resume();
        setTimeout(() => {
            this.pausable.pause();
        }, 1500)
    }


  onCerrarClick(): void {
    this.dialogRef.close();
  }


}
