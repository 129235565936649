<div class="cont_nav--mobiles" fxFlex="100" *ngIf="navMobile === true">
    <div class="cont_itmes" fxFlex="70" fxLayout="column">
        <span class="close" (click)="validMenu()">X</span>
        <div class="items_group">
            <button class="btn btn_black" [routerLink]="'/login'">Iniciar Sesión</button>
            <button class="btn btn_blue" [routerLink]="'/registro'" [queryParams]="{registro: 'empresa'}">Registrate
                como Empresa</button>
            <button class="btn btn_orange" [routerLink]="'/registro'" [queryParams]="{registro: 'candidato'}">Registrate
                como candidato</button>
            <button class="btn btn_purple" [routerLink]="'/okupa'">Okupa</button>
        </div>
        <div class="items_group">
            <button class="btn btn_yellow" [routerLink]="'/login'">Publicar tu
                vacante</button>
        </div>
        <div class="items_group" fxLayout="row grap">
            <a fxFlex="100" *ngFor="let ite of items" fxLayoutAlign="center center" href="{{ite.url}}"
                class="btn btn_grey">{{ite.name}}</a>
        </div>
        <div class="items_group" fxLayout="row" fxLayoutAlign="center center">
            <a href="https://www.tiktok.com/@en.modo.ok?lang=es" target="_blank">
                <img src="../../../../assets/images/logos/tik-tok.png" alt="">
            </a>
            <a href="https://www.linkedin.com/company/ok-empleos/" target="_blank">
                <img src="../../../../assets/images/logos/linke_black.svg" alt="">
            </a>
            <a href="https://www.instagram.com/ok.empleos/" target="_blank">
                <img src="../../../../assets/images/logos/insta_black.svg" alt="">
            </a>
            <a href="https://www.facebook.com/OkEmpleosColombia" target="_blank">
                <img src="../../../../assets/images/logos/face_black.svg" alt="">
            </a>
        </div>
    </div>
</div>

<div fxFlex="100" class="cont_nav--comp">
    <nav fxFlex="100" class="navbar nav_desktop">
        <div class="cont_nav" fxLayoutAlign="space-between center">
            <div fxFlex="35" fxFlex.gt-sm="50" class="item_nav item_nav--left" fxLayout="row" fxLayoutAlign="start center">
                <img class="ok_log" src="../../../assets/images/logos/log_ok.png" alt="" (click)="back()">
                <button class="btn_nav btn-navy" [routerLink]="'#'">NUESTRA COMPAÑÍA</button>
                <button class="btn_nav btn-clean" [routerLink]="'/empleos'">NUESTRAS VACANTES</button>
            </div>
            <div fxFlex="50" fxFlex.gt-sm="50" class="item_nav" fxLayout="row" fxLayoutAlign="end center">
                <!-- <a class="btn_nav btn-blog btn-blog--right" href="https://okempleos.com/contactenos/">CONTACTO</a> -->
                <button class="btn_nav btn-orange" [routerLink]="'/login'">INICIAR SESION</button>
                <ul class="ul_second" fxLayout="row" fxLayoutAlign="center center">
                    <li class="li_second">
                        <button class="btn_nav btn-wiht--two" [routerLink]="'/registro'">REGISTRARME</button>
                        <!-- <ul class="items_otions">
                            <li><a [routerLink]="'/registro'" [queryParams]="{registro: 'empresa'}">Empresa</a></li>
                            <li><a [routerLink]="'/registro'" [queryParams]="{registro: 'candidato'}">Candidato</a>
                            </li>
                        </ul> -->
                    </li>
                </ul>
            </div>
        </div>
    </nav>
    <nav class="navbar nav_movile">
        <div class="cont_nav" fxLayoutAlign="space-between center">
            <div fxFlex="100" class="item_nav" fxLayoutAlign="space-between center">
                <img class="ok_log item_nav--left" src="../../../assets/images/logos/log_ok.png" alt=""
                    (click)="back()">
                <img class="hamburger" (click)="validMenu()" src="../../../assets/images/logos/hamburger_icon.png"
                    alt="">
            </div>
        </div>
    </nav>
</div>