import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-completa-perfil',
  templateUrl: './modal-completa-perfil.component.html',
  styleUrls: ['./modal-completa-perfil.component.scss']
})
export class ModalCompletaPerfilComponent implements OnInit {
  public itemsChecklist = [];

  constructor(
    public dialogRef: MatDialogRef<ModalCompletaPerfilComponent>,
    @Inject(MAT_DIALOG_DATA) public data: string[],
  ) {
    console.log('data data', this.data);
  }

  ngOnInit(): void {
    this.validCampos();
    
  }

  onCerrarClick(): void {
    this.dialogRef.close();
  }

  validCampos() {
    var datos = [];
    datos =this.data;
    for(let item of datos){
      if(item.checked === false){
        this.itemsChecklist.push(item);
      }
    }
  }
}
